var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c(
        "b-button",
        {
          attrs: { variant: "primary", disabled: _vm.disabled || _vm.loading },
          on: { click: _vm.reloadDataSheets },
        },
        [
          _vm.loading ? _c("b-spinner") : _vm._e(),
          _vm._v("\n    データシートの取得 " + _vm._s(_vm.history) + "\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          attrs: {
            variant: _vm.datasheetsPreviewEnabled ? "success" : "",
            disabled: _vm.disabled || _vm.loading || _vm.updatedAt === null,
          },
          on: {
            click: function ($event) {
              return _vm.switchDatasheetsPreview()
            },
          },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.datasheetsPreviewEnabled ? "✔ " : "") +
              "データシートプレビュー\n  "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }