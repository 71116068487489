import { MarkdownParser } from 'kamigame-web';
import { makeCustomTOC, makeTOC } from 'wiki-shared/toc';
const ejs = require('./ejs.min');

import PagePartialParser from '@/components/MarkdownEditor/PagePartialParser';
import DatasheetsDB from '@/indexeddb/DatasheetsDB';
import extractDataSheetNamesFromEjs from '@/service/extractDataSheetNamesFromEjs';

export class KamigameMarkdownEditorConvertor {
  pagePartialParser: PagePartialParser;

  constructor(game: string) {
    this.pagePartialParser = new PagePartialParser(game);
  }

  convertEjsMarkdown(markdown: string, useDataSheetPreview: boolean): string {
    try {
      let data: any = {};
      const sheetNames = extractDataSheetNamesFromEjs(markdown);
      sheetNames.forEach((sheetName) => (data[sheetName] = 'ダミー'));
      return ejs.render(markdown, data);
    } catch (e) {
      if (e instanceof Error) {
        return e.toString();
      }
      return `${e}`;
    }
  }

  replacePagePartial(content: string): { html: string; scripts: string[] } {
    let replaced = content;
    let scriptsArray: string[] = [];

    const pagePartialMarkdowns = this.pagePartialParser.getPagePartialMarkdownsByContent(replaced);
    Object.keys(pagePartialMarkdowns).forEach((templateLiteral) => {
      const pToSectionRegExp = new RegExp(`<p( .+?>[\\s\\S]*?)</p>`, 'g');
      let matched;
      while ((matched = pToSectionRegExp.exec(replaced)) != null) {
        if (!matched[0].includes('{template')) {
          continue;
        }

        replaced = replaced.replace(matched[0], `<section${matched[1]}</section>`);
      }

      const pagePartialRegExp = new RegExp(`${templateLiteral}`, 'g');
      const { html, scripts } = this.toHTMLWithScripts(pagePartialMarkdowns[templateLiteral], false, false);
      replaced = replaced.replace(pagePartialRegExp, html);
      scriptsArray = scriptsArray.concat(scripts);
    });

    return { html: replaced, scripts: [...new Set(scriptsArray)] };
  }

  toHTMLWithScripts(
    markdown: string,
    withTokenMarker: boolean,
    useDataSheetPreview: boolean
  ): { html: string; scripts: string[] } {
    let data: any = {};
    if (useDataSheetPreview) {
      // データシート表示制限:10項目まで
      const datasheets = DatasheetsDB.getDatasheetsDB() ?? {};
      for (const name in datasheets) {
        data[name] = datasheets[name].slice(0, 10);
      }
      if (Object.keys(data).length !== 0) {
        try {
          markdown = ejs.render(markdown, data);
        } catch (e) {
          const html = e instanceof Error ? e.toString() : `${e}`;
          return { html, scripts: [] };
        }
      }
    }
    const defaultParser = new MarkdownParser('dummy', markdown, '', undefined, true, 0, withTokenMarker);
    const title = defaultParser.getTitle() ? `<h1>${defaultParser.getTitle()}</h1>` : '';

    const content = title + defaultParser.renderEntireSections(false).toString();
    return { html: content, scripts: Array.from(defaultParser.helperScripts) };
  }

  toWidgetHTMLWithScripts(content: string): ReturnType<KamigameMarkdownEditorConvertor['toHTMLWithScripts']> {
    return this.toHTMLWithScripts(content, true, true);
  }

  toWikiPageHTMLWithScripts(markdown: string, description: string): { html: string; scripts: string[] } {
    const defaultParser = new MarkdownParser(
      'dummy',
      this.convertEjsMarkdown(markdown, false),
      '',
      undefined,
      true,
      0,
      true
    );
    const title = defaultParser.getTitle() ? `<h1>${defaultParser.getTitle()}</h1>` : '';

    const sections = defaultParser.renderBySections();
    const descriptionLead =
      sections._ && sections._.toString().includes('class="lead_text"')
        ? ''
        : `<p><span class="lead_text">${description}</p></span>`;

    let body = defaultParser.renderEntireSections(false).toString();

    let tocHtml = '';
    const toc = defaultParser.getTOC();
    if (toc && toc.length > 0) {
      // カスタム目次
      tocHtml = makeCustomTOC(toc, 'contents', 'kamigame-table-of-contents', 'table_of_contents');

      // 目次部分は独自で構築するので本文内の目次ブロックを削除する
      // <h2 id="目次">目次</h2>...<h2>hoge</h2>... → <h2>hoge</h2>...
      body = body.replace(/<h2[\s\S]*?id="目次"[\s\S]*?(<h2)/, '$1');
    } else {
      // 通常の目次
      tocHtml = makeTOC(
        {
          article_bodies: Object.fromEntries(
            Object.keys(sections)
              .filter((k) => k !== '_' && k !== '目次')
              .map((k) => [k, sections[k]])
          ),
        },
        'contents',
        'kamigame-table-of-contents',
        'table_of_contents',
        '目次'
      );
    }

    const content = title + descriptionLead + tocHtml + body;
    const { html: replacePagePartialContentHtml, scripts: replacePagePartialContentScripts } =
      this.replacePagePartial(content);
    const scripts = (Array.from(defaultParser.helperScripts) as string[]).concat(replacePagePartialContentScripts);
    return { html: replacePagePartialContentHtml, scripts: [...new Set(scripts)] };
  }
  toCompletedMarkdown(markdown: string): string {
    const pagePartial = this.pagePartialParser.getPagePartialMarkdownsByContent(markdown);
    if (!pagePartial) {
      return markdown;
    }

    const replaceFns = Object.keys(pagePartial).map((key) => (md: string) => md.replace(key, pagePartial[key]));

    const completedMarkdown = replaceFns.reduce((md, fn) => fn(md), markdown);
    return completedMarkdown;
  }

  getParsedTokens(markdown: string) {
    const parser = new MarkdownParser('dummy', this.convertEjsMarkdown(markdown, false), '', undefined, true, 0, true);
    return parser.parsed;
  }

  getCustomTOC(markdown: string) {
    const parser = new MarkdownParser('dummy', this.convertEjsMarkdown(markdown, false), '', undefined, true, 0, true);
    return parser.getTOC();
  }
}
