var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { cols: "12" } },
              [
                _c(
                  "b-card",
                  { attrs: { "header-tag": "header", "footer-tag": "footer" } },
                  [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _c("strong", [_vm._v("アーカイブ設定(開発中)")]),
                    ]),
                    _vm._v(" "),
                    _vm.isArchived
                      ? _c("div", { staticClass: "mb-3" }, [
                          _c("ol", [
                            _c("li", [
                              _vm._v("この Wiki の記事を"),
                              _c("strong", [_vm._v("「再度公開します」")]),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                "プライベートモードの場合は Wiki メンバーであればアクセスできるようになります"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v("後から再度アーカイブすることも可能です"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { attrs: { slot: "footer" }, slot: "footer" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "danger" },
                                  on: { click: _vm.toggleArchive },
                                },
                                [_vm._v("アーカイブを戻して再度公開する")]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _c("div", { staticClass: "mb-3" }, [
                          _c("ol", [
                            _c("li", [
                              _vm._v("この Wiki の記事を"),
                              _c("strong", [
                                _vm._v("「完全に非公開にします」"),
                              ]),
                              _vm._v("(サーバから 404 を返します)"),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                "Wiki メンバーであってもアクセスできなくなります"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v("管理画面は引き続きアクセス可能です"),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v("後から再度公開することは可能です"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { attrs: { slot: "footer" }, slot: "footer" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "danger" },
                                  on: { click: _vm.toggleArchive },
                                },
                                [
                                  _vm._v(
                                    "アーカイブして記事を完全に非公開にする"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }