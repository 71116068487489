
import KamigameVue from '@/KamigameVue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'kamigame-wiki-search-bar',
})
export default class WikiSearchBar extends KamigameVue {
  @Prop()
  target!: string;

  @Prop()
  codeMirror!: any;

  searchText: string = '';

  searchCursor: number = 0;
  searchResults: { index: number; row: number; col: number }[] = [];
  searchReplace: string = '';
  toggleReplace: boolean = false;

  get searchResultsNumber(): number {
    return this.searchResults.length !== 0 ? this.searchCursor + 1 : 0;
  }

  get searchResultsCount(): number {
    return this.searchResults.length;
  }

  @Watch('target')
  onTextUpdated() {
    if (this.searchText !== '') {
      this.searchInput(false);
    }
  }

  async searchFormInputFocus() {
    // タイミング調整のために2個必要
    await this.$nextTick();
    await this.$nextTick();
    (this.$refs.searchFormInput as any).focus();
  }

  searchAll(text: string, searchText: string): { index: number; row: number; col: number }[] {
    const lines = text.split('\n');
    const result: { index: number; row: number; col: number }[] = [];
    let searchIndex = 0;
    lines.forEach((line, rowIndex) => {
      let colIndex = 0;
      while (-1 !== (colIndex = line.indexOf(searchText, colIndex))) {
        result.push({ index: searchIndex + colIndex, row: rowIndex, col: colIndex });
        colIndex += searchText.length;
      }
      searchIndex += line.length + 1;
    });
    return result;
  }

  reset() {
    const marks = this.codeMirror.getAllMarks();
    marks
      .filter((e: any) => e.className.includes('search-marker'))
      .forEach((e: { clear: () => void }) => {
        e.clear();
      });
    this.searchCursor = 0;
    this.searchResults = [];
  }

  searchInput(useSelection: boolean) {
    this.reset();
    if (this.searchText === '') {
      return;
    }
    const searchResults = this.searchAll(this.target, this.searchText);
    this.markText(this.codeMirror, useSelection ? 0 : -1, searchResults);
    this.searchCursor = 0;
    this.searchResults = searchResults;
  }

  markText(codeMirror: any, selectIndex: number, searchResults: { index: number; row: number; col: number }[]) {
    const marks = codeMirror.getAllMarks();
    marks
      .filter((e: any) => e.className.includes('search-marker'))
      .forEach((e: { clear: () => void }) => {
        e.clear();
      });
    searchResults.forEach((searchResult, index) => {
      const { row, col } = searchResult;
      const marker = [
        { line: row, ch: col },
        { line: row, ch: col + this.searchText.length },
      ];
      const className = index === selectIndex ? 'search-marker-select' : 'search-marker';
      if (className === 'search-marker-select') {
        codeMirror.setSelection(...marker);
      }
      codeMirror.markText(...marker, { className });
    });
  }

  replace() {
    if (this.searchResults.length === 0) {
      return;
    }
    const { index } = this.searchResults[this.searchCursor];
    const target = this.target.slice(0, index) + this.searchReplace + this.target.slice(index + this.searchText.length);
    this.$emit('replace', target);
  }

  replaceAll() {
    const reg = new RegExp(this.searchText, 'g');
    const target = this.target.replace(reg, this.searchReplace);
    this.$emit('replace', target);
    this.reset();
  }

  cursorShortcut(event: KeyboardEvent) {
    event.shiftKey === true ? this.cursorPrev() : this.cursorNext();
  }

  cursorNext() {
    this.searchCursor++;
    if (this.searchCursor === this.searchResults.length) {
      this.searchCursor = 0;
    }
    this.markText(this.codeMirror, this.searchCursor, this.searchResults);
  }

  cursorPrev() {
    this.searchCursor--;
    if (this.searchCursor === -1) {
      this.searchCursor = this.searchResults.length - 1;
    }
    this.markText(this.codeMirror, this.searchCursor, this.searchResults);
  }
}
