
import DatasheetsDB from '@/indexeddb/DatasheetsDB';

import KamigameVue from '@/KamigameVue';
import { downloadWikiDataSheet } from '@/service/CloudStorage';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import format from 'date-fns/format';

@Component({
  name: 'datasheets-loader-button',
})
export default class DatasheetsLoadButton extends KamigameVue {
  loading: boolean = false;
  updatedAt: Date | null = null;
  datasheetsPreviewEnabled: boolean = true;

  @Prop()
  disabled!: boolean;
  condition!: string;

  @Prop()
  onLoaded!: () => void;

  async mounted() {
    const wikiId = await this.wikiId;
    await DatasheetsDB.load(wikiId);
    this.updatedAt = DatasheetsDB.getHistory();
    this.onLoaded();
  }

  switchDatasheetsPreview() {
    this.datasheetsPreviewEnabled = !this.datasheetsPreviewEnabled;
    DatasheetsDB.setDisabled(!this.datasheetsPreviewEnabled);
    this.onLoaded();
  }
  get history(): string {
    return this.updatedAt ? format(this.updatedAt, '(MM/dd HH:mm)') : '(未取得)';
  }

  async reloadDataSheets() {
    try {
      this.loading = true;
      const sheets: { [key: string]: { [key: string]: string }[] } = {};
      const datasheets = await this.apiClient
        .GET('/admin/wiki/{name}/data-sheet', {
          params: {
            path: {
              name: this.wikiName,
            },
          },
        })
        .then((r) => {
          if (r.error) {
            throw r.error;
          }

          return r.data;
        });

      if (datasheets.sheets) {
        for (const datasheet of datasheets.sheets) {
          if (datasheet.sheetName !== undefined && datasheet.originBucketPath !== undefined) {
            let sheetData: string;
            if (datasheet.sheetData === undefined || datasheet.sheetData === '') {
              sheetData = (await downloadWikiDataSheet(
                this.apiClient,
                this.wikiName,
                datasheet.originBucketPath
              )) as string;
            } else {
              sheetData = datasheet.sheetData;
            }
            const sheetsObject = JSON.parse(sheetData) as string[][];
            sheets[datasheet.sheetName] = this.convertTableArrayToJSON(sheetsObject);
          }
        }
      }
      const updatedAt = new Date();
      const wikiId = await this.wikiId;
      await DatasheetsDB.set(sheets, wikiId, updatedAt);
      this.updatedAt = updatedAt;
      this.onLoaded();
      this.$bvToast.toast(format(this.updatedAt, 'HH:mm:ss にデータシートを取得しました'), {
        title: 'データシートを取得しました',
        autoHideDelay: 5000,
        variant: 'success',
      });
    } catch (e) {
      if (e instanceof Error) {
        this.$bvToast.toast('エラー内容: ' + e.toString(), {
          title: 'データシートの取得に失敗しました',
          autoHideDelay: 5000,
          variant: 'danger',
        });
      } else {
        console.error(e);
      }
    } finally {
      this.loading = false;
    }
  }

  convertTableArrayToJSON(sheetsObject: string[][]): { [key: string]: string }[] {
    const properties = sheetsObject.shift() as string[];
    return sheetsObject.map((sheet) => {
      const newObj: { [key: string]: string } = {};
      properties.forEach((p, idx) => {
        newObj[p] = sheet[idx];
      });
      return newObj;
    });
  }
}
