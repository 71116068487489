
import { BModal } from 'bootstrap-vue';
import format from 'date-fns/format';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import VueRouter from 'vue-router';
const ejs = require('../../components/MarkdownEditor/ejs.min');

import KamigameVue from '@/KamigameVue';
import { components } from '@/api-client/schema';
import { MarkdownEditor } from '@/components';
import DatasheetsLoadButton from '@/components/DatasheetsLoadButton.vue';
import extractNotAllowedDomainsFromErrorMessage from '@/service/ExtractNotAllowedDomainsFromErrorMessage';
import DatasheetsDB from '@/indexeddb/DatasheetsDB';

@Component({
  name: 'wiki-page-partial-edit',
  components: {
    'datasheets-loader-button': DatasheetsLoadButton,
    'kamigame-markdown-editor': MarkdownEditor,
  },
})
export default class WikiPagePartialEdit extends KamigameVue {
  dateFormat = format;

  pagePartialID = '';
  name: string = '';
  text: string = '';
  partialName = '';
  partialText = '';
  disabled: boolean = false;
  editable: boolean = true;
  draftId: string = '';
  draftName: string = '';
  draftText: string = '';
  draftAuthorName: string = '';
  draftUpdatedAt?: Date;
  autoDraftSave: boolean = false;
  autoSaveTimeoutID: number = 0;
  requiredColumnsPerSheet: components['schemas']['v1ColumnNameListPerSheets']['sheets'] = {};
  pagePartialConditions: string[] = [''];
  selectedPagePartialConditionIdx: number = -1;

  @Watch('pagePartial')
  onPagePartialChanged() {
    this.init();
  }

  @Watch('text')
  onTextChanged() {
    if (
      this.autoDraftSave &&
      typeof this.draftUpdatedAt !== 'undefined' &&
      !this.disabled &&
      this.autoSaveTimeoutID === 0
    ) {
      this.autoSaveTimeoutID = window.setTimeout(() => {
        if (this.autoDraftSave) {
          this.saveAsDraft();
        }
        this.autoSaveTimeoutID = 0;
      }, 30 * 1000);
    }
  }

  @Watch('selectedPagePartialConditionIdx')
  onSelectedPagePartialConditionIdxChanged() {
    if (this.selectedPagePartialConditionIdx === -1) {
      DatasheetsDB.setDataLength(10);
    } else {
      DatasheetsDB.setCondition(this.pagePartialConditions[this.selectedPagePartialConditionIdx]);
    }
    this.$refs.Editor.onEditorChange();
  }

  $refs!: {
    Editor: MarkdownEditor;
    checkDeleteDraftModal: BModal;
    checkPublishModal: BModal;
    confirmPublishModal: BModal;
  };

  mounted() {
    this.init();
  }

  async init() {
    if (this.pagePartial) {
      await this.getWikiPagePartial();
    } else {
      this.resetPagePartialStates();
    }

    this.setPartialDocumentTitle();
    this.editable = this.$ability.can('ACTION_wiki_page_partial_update', 'OBJ_default');
  }

  onDatasheetsLoaded() {
    this.$refs.Editor.onEditorChange();
  }

  async getWikiPagePartial() {
    await this.apiClient
      .GET('/admin/wiki/{wikiName}/page-partial/{wikiPagePartialID}', {
        params: {
          path: {
            wikiName: this.wikiName,
            wikiPagePartialID: this.pagePartial,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then(async (response) => {
        this.name = response.name || '';
        this.text = response.body || '';
        this.partialName = this.name;
        this.partialText = this.text;
        if (response.id) {
          this.pagePartialID = response.id;
        }
        if (this.pagePartialID) {
          await this.loadDraft();
        }
      });
  }

  resetPagePartialStates() {
    this.pagePartialID = '';
    this.name = '';
    this.text = '';
    this.disabled = false;
    this.editable = true;
  }

  async create(params: components['schemas']['v1CreateWikiPagePartialRequest']) {
    await this.apiClient
      .POST('/admin/wiki/{wikiName}/page-partial', {
        params: {
          path: {
            wikiName: this.wikiName,
          },
        },
        body: params,
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then((response) => {
        if (response.id) {
          this.pagePartialID = response.id || '';
        }
      });
  }

  async update(params: components['schemas']['v1EditWikiPagePartialRequest']) {
    await this.apiClient
      .PUT('/admin/wiki/{wikiName}/page-partial/{wikiPagePartialName}', {
        params: {
          path: {
            wikiName: this.wikiName,
            wikiPagePartialName: this.pagePartialID,
          },
        },
        body: params,
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      });
  }

  async specifyRequiredColumns() {
    this.disabled = true;

    let maybeReferSheet = false;
    try {
      ejs.render(this.text, {}, {});
    } catch (err: any) {
      if (err instanceof ReferenceError && err.message.includes('is not defined')) {
        maybeReferSheet = true;
      }
    }

    const requiredColumnsPerSheet: components['schemas']['v1ColumnNameListPerSheets']['sheets'] = {};
    if (!maybeReferSheet) {
      this.requiredColumnsPerSheet = {};
      this.disabled = false;
      return;
    }

    for (const condition of this.pagePartialConditions) {
      let datasheets;
      if (condition === '') {
        datasheets = DatasheetsDB.getDatasheetsDB({ dataLength: undefined, condition: undefined });
      } else {
        datasheets = DatasheetsDB.getDatasheetsDB({ dataLength: undefined, condition });
      }
      if (!datasheets) {
        this.setFlashMessage(
          'danger',
          'データシートの読み込みに失敗しました。あらかじめデータシートを取得した上で保存してください'
        );
        this.requiredColumnsPerSheet = {};
        this.disabled = false;
        return;
      }

      const resource = new Proxy(datasheets, {
        get(target, key: string, receiver) {
          if (!(key in datasheets)) {
            return Reflect.get(target, key, receiver);
          }

          const sheetName = String(key);
          if (!requiredColumnsPerSheet[sheetName]) {
            requiredColumnsPerSheet[sheetName] = { columns: [] };
          }

          return target[key].map((row) => {
            return new Proxy(row, {
              get(target, column: string) {
                if (!requiredColumnsPerSheet[sheetName]) {
                  requiredColumnsPerSheet[sheetName] = { columns: [] };
                }

                if (!requiredColumnsPerSheet[sheetName].columns?.includes(column)) {
                  requiredColumnsPerSheet[sheetName].columns?.push(column);
                }

                return target[column];
              },
            });
          });
        },
      });

      try {
        ejs.render(this.text, resource);
      } catch (err) {
        console.error(err);
      }
    }

    this.requiredColumnsPerSheet = requiredColumnsPerSheet;
    this.disabled = false;
  }

  async save() {
    if (this.disabled) {
      return;
    }

    if (this.name === '') {
      this.setFlashMessage('danger', '記事部品名を入力してください');
      return;
    }

    const params = {
      name: this.name,
      body: this.text,
      columns: {
        sheets: this.requiredColumnsPerSheet ?? {},
      },
    };

    const isUpdate = !!this.pagePartialID;
    await (isUpdate ? this.update(params) : this.create(params))
      .then(async () => {
        this.partialName = this.name;
        this.partialText = this.text;

        if (this.draftId) {
          await this.deleteDraft();
        }

        this.router.push(
          {
            name: 'wiki_page_partial_list',
          },
          () => {
            if (isUpdate) {
              this.setFlashMessage('success', '記事部品を更新しました。');
            } else {
              this.setFlashMessage('success', '記事部品を保存しました。');
            }
          }
        );
      })
      .catch((e: Error) => {
        if (isUpdate) {
          const domains = extractNotAllowedDomainsFromErrorMessage(e.message);
          if (domains.length !== 0) {
            this.setFlashMessage('danger', 'これらのドメインは記事中に使用できません: ' + domains.join(', '));
            return;
          }
          this.setFlashMessage('danger', '記事部品の更新に失敗しました。');
        } else {
          const domains = extractNotAllowedDomainsFromErrorMessage(e.message);
          if (domains.length !== 0) {
            this.setFlashMessage('danger', 'これらのドメインは記事中に使用できません: ' + domains.join(', '));
            return;
          }
          this.setFlashMessage('danger', '記事部品の作成に失敗しました');
        }
      });

    this.disabled = false;
  }

  get isDraftContents() {
    return this.name === this.draftName && this.text === this.draftText;
  }

  async loadDraft() {
    await this.apiClient
      .GET('/admin/wiki/{wikiName}/page-partial/{wikiPagePartialID}/draft', {
        params: {
          path: {
            wikiName: this.wikiName,
            wikiPagePartialID: this.pagePartialID,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }
        return r.data;
      })
      .then((response) => {
        this.draftId = response.id || '';
        this.draftName = response.name || '';
        this.draftText = response.body || '';
        this.draftAuthorName = response.lastAuthor ? response.lastAuthor.nickname || '' : '';
        this.draftUpdatedAt = response.updatedAt ? new Date(response.updatedAt) : undefined;
        this.name = this.draftName || this.name;
        this.text = this.draftText || this.text;
      });
  }

  async saveAsDraft() {
    if (this.disabled) {
      return;
    }

    if (this.name === '') {
      this.setFlashMessage('danger', '記事部品名を入力してください');
      return;
    }

    if (!this.pagePartialID) {
      // 新規作成の場合、公開の確認モーダルを表示
      this.$refs.confirmPublishModal.show();
    } else {
      // 既存の場合は下書き保存を行う
      await this.performSaveAsDraft();
    }
  }

  async confirmPublish() {
    await this.performSaveAsDraft();
  }

  async performSaveAsDraft() {
    this.disabled = true;

    const params = {
      name: this.name,
      body: this.text,
      columns: { sheets: {} }, // NOTE: 下書きはデータシートのカラム名を検証しない
    };

    await this.apiClient
      .POST('/admin/wiki/{wikiName}/page-partial/{wikiPagePartialID}/draft', {
        params: {
          path: {
            wikiName: this.wikiName,
            wikiPagePartialID: this.pagePartialID || '0',
          },
        },
        body: params,
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then(async (response) => {
        if (!this.pagePartial && response.wikiPagePartial && response.wikiPagePartial.id) {
          this.pagePartialID = response.wikiPagePartial.id;
          this.draftName = this.name;
          this.draftText = this.text;
          await this.$nextTick();
          this.router.push(
            {
              name: 'wiki_page_partial_edit',
              params: {
                name: this.wikiName,
                wikiPagePartialID: this.pagePartialID,
              },
            },
            () => {
              this.draftSaveSuccessfullProcess(response);
            }
          );
        } else {
          this.draftSaveSuccessfullProcess(response);
        }
      })
      .catch((e) => {
        this.setFlashMessage('danger', '下書きの保存に失敗しました。');
      })
      .finally(() => {
        this.disabled = false;
      });
  }

  draftSaveSuccessfullProcess(response: components['schemas']['v1WikiPagePartialDraft']) {
    this.draftId = response.id || '';
    this.draftName = response.name || '';
    this.draftText = response.body || '';
    this.draftAuthorName = response.lastAuthor ? response.lastAuthor.nickname || '' : '';
    this.draftUpdatedAt = response.updatedAt ? new Date(response.updatedAt) : undefined;
    this.$bvToast.toast(this.dateFormat(this.draftUpdatedAt as Date, 'HH:mm:ss に下書きを保存しました'), {
      title: '下書きを保存しました',
      autoHideDelay: 5000,
      variant: 'success',
    });
  }

  async deleteDraft() {
    await this.apiClient
      .DELETE('/admin/wiki/{wikiName}/page-partial/{wikiPagePartialID}/draft', {
        params: {
          path: {
            wikiName: this.wikiName,
            wikiPagePartialID: this.pagePartialID,
          },
        },
      })
      .then(() => {
        this.name = this.partialName;
        this.text = this.partialText;
        this.resetDraft();
        this.setFlashMessage('success', '下書きを削除しました。');
      })
      .catch(() => {
        this.setFlashMessage('danger', '下書きの削除に失敗しました。');
      });
  }

  resetDraft() {
    this.draftId = '';
    this.draftName = '';
    this.draftText = '';
    this.draftAuthorName = '';
    this.draftUpdatedAt = undefined;
  }

  showCheckDeleteDraftModal() {
    const modal = this.$refs.checkDeleteDraftModal;
    modal.show();
  }

  showCheckPublishModal() {
    if (this.name === '') {
      this.setFlashMessage('danger', '記事部品テンプレートの名前 を入力してください');
      return;
    }

    this.specifyRequiredColumns();

    const modal = this.$refs.checkPublishModal;
    modal.show();
  }

  switchAutoDraftSave() {
    if (this.draftId === '') {
      this.saveAsDraft();
    }

    this.autoDraftSave = !this.autoDraftSave;
    if (!this.autoDraftSave) {
      this.disableAutoSave();
    }
  }

  disableAutoSave() {
    window.clearTimeout(this.autoSaveTimeoutID);
    this.autoSaveTimeoutID = 0;
    this.autoDraftSave = false;
  }

  isEditorClean(): boolean {
    return (
      (this.name === this.partialName && this.text === this.partialText) ||
      (this.name === this.draftName && this.text === this.draftText)
    );
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    if (this.isEditorClean()) {
      next();
      return;
    }

    if (window.confirm('変更が保存されていませんが、このページから離れますか？')) {
      next();
    }
  }

  setPartialDocumentTitle() {
    if (!this.partialName) {
      return;
    }

    this.setDocumentTitle(`${this.partialName} | ${this.routeDocumentTitle}`);
  }

  get pagePartial() {
    return this.route.params.wikiPagePartialID;
  }

  get requiredSheets() {
    return Object.keys(this.requiredColumnsPerSheet ?? {});
  }
}
