import localforage from 'localforage';

import { extractTemplate } from 'wiki-shared/page-partial';

const datasheetsDBKeyName = 'datasheets_db';
const updatedAtKeyName = 'datasheets_updated_at';

type TempDatasheets = { [key: string]: { [key: string]: string }[] } | null;

export default class DatasheetsDB {
  private static tempDatasheetsDB: TempDatasheets = null;
  private static tempUpdatedAt: Date | null = null;
  private static disabled: boolean = false;

  private static filterOption: {
    condition?: string;
    dataLength?: number;
  } = {
    condition: undefined,
    dataLength: 10, // NOTE: デフォルトは 10 件のみ表示するように
  };

  static async set(data: any, wikiId: string, updatedAt: Date): Promise<void> {
    await localforage.setItem(datasheetsDBKeyName + '_' + wikiId, data);
    await localforage.setItem(updatedAtKeyName + '_' + wikiId, updatedAt.toString());
    DatasheetsDB.tempDatasheetsDB = data;
    DatasheetsDB.tempUpdatedAt = updatedAt;
    return;
  }

  static async load(wikiId: string): Promise<void> {
    const data = (await localforage.getItem(datasheetsDBKeyName + '_' + wikiId)) as TempDatasheets;
    let updatedAt = (await localforage.getItem(updatedAtKeyName + '_' + wikiId)) as string | null;
    if (data !== null) {
      DatasheetsDB.tempDatasheetsDB = data;
    }
    if (updatedAt !== null) {
      DatasheetsDB.tempUpdatedAt = new Date(updatedAt);
    }
  }

  static setDisabled(flag: boolean) {
    DatasheetsDB.disabled = flag;
  }

  // getHistory は先に load() を行い DB から読み込む必要があります
  static getHistory(): Date | null {
    if (DatasheetsDB.disabled) {
      return null;
    }
    if (DatasheetsDB.tempUpdatedAt !== null) {
      return DatasheetsDB.tempUpdatedAt;
    }
    return null;
  }

  // getDatasheetsDB は先に load() を行い DB から読み込む必要があります
  static getDatasheetsDB(
    overwriteFilterOption: { dataLength: number | undefined; condition: string | undefined } | null = null
  ): TempDatasheets {
    if (DatasheetsDB.disabled) {
      return null;
    }
    if (DatasheetsDB.tempDatasheetsDB === null) {
      return null;
    }

    const data = Object.assign({}, DatasheetsDB.tempDatasheetsDB);
    const filterOption = overwriteFilterOption ?? DatasheetsDB.filterOption;
    if (filterOption.condition === '') {
      return {};
    }

    if (filterOption.dataLength !== undefined) {
      for (const name in data) {
        data[name] = data[name].slice(0, filterOption.dataLength);
      }
    }

    if (filterOption.condition !== undefined) {
      const conditonMap = new Map<string, { key: string; value: string }>();

      let invalid = false;
      filterOption.condition.split(' ').forEach((sheetCondition) => {
        const matched = sheetCondition.match(/([^\s:=]+):([^\s:=]+)=([^\s:=]+)/);
        if (!matched) {
          invalid = true;
          return;
        }

        const [, sheetName, key, value] = matched;
        conditonMap.set(sheetName, { key, value });
      });
      if (invalid) {
        return {};
      }

      for (const name in data) {
        const condition = conditonMap.get(name);
        if (!condition) {
          continue;
        }

        data[name] = data[name].filter((row) => row[condition.key] === condition.value);
      }
    }

    return data;
  }

  static setCondition(condition: string) {
    DatasheetsDB.filterOption.condition = condition;
    DatasheetsDB.filterOption.dataLength = undefined;
  }

  static setDataLength(dataLength: number) {
    DatasheetsDB.filterOption.dataLength = dataLength;
    DatasheetsDB.filterOption.condition = undefined;
  }
}
