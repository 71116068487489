var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      !_vm.editable
        ? _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
            _vm._v(" 編集権限がないため、閲覧専用モードで表示しています。 "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "animated fadeIn wiki-page-partial-edit" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-modal",
                    {
                      attrs: { id: "deleteDraftModal", lazy: "" },
                      on: { ok: _vm.deleteDraft },
                    },
                    [
                      _vm.draftId
                        ? _c("p", [
                            _vm._v(
                              "下書きを削除すると、他のユーザがこの下書きを利用できなくなります。"
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p", [_vm._v("下書きを削除してもよろしいですか？")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-modal",
                    {
                      ref: "checkDeleteDraftModal",
                      attrs: { lazy: "" },
                      on: { ok: _vm.showCheckPublishModal },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "\n            現在保存されている下書きは削除されます\n            "
                        ),
                        _c("br"),
                        _vm._v(
                          "下書きを削除すると全てのユーザがこの下書きを利用できなくなります\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v("変更内容を保存しますか？")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-modal",
                    {
                      ref: "checkPublishModal",
                      attrs: { lazy: "" },
                      on: { ok: _vm.save },
                    },
                    [
                      _c("p", [_vm._v("この記事部品は公開されます")]),
                      _vm._v(" "),
                      _vm.requiredSheets.length > 0
                        ? [
                            _c("p", [
                              _vm._v(
                                "この記事部品で参照されているカラムは以下の通りです"
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.requiredColumnsPerSheet,
                                function (requiredColumns, sheetName) {
                                  return _c("li", { key: sheetName }, [
                                    requiredColumns && requiredColumns.columns
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(sheetName) +
                                              ": " +
                                              _vm._s(
                                                requiredColumns.columns.join(
                                                  ", "
                                                )
                                              )
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(_vm._s(sheetName) + ": なし"),
                                        ]),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p", [_vm._v("よろしいですか？")]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "b-modal",
                    {
                      ref: "confirmPublishModal",
                      attrs: { title: "公開の確認", lazy: "" },
                      on: { ok: _vm.confirmPublish },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "この記事部品はまだ存在しないため、下書きの保存と同時に公開されます。"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v("公開してもよろしいですか？")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    {
                      attrs: { "header-tag": "header", "footer-tag": "footer" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "header" }, slot: "header" },
                        [
                          _c("b-form-input", {
                            attrs: {
                              disabled: !_vm.editable,
                              type: "text",
                              placeholder: "記事部品テンプレートの名前を入力",
                            },
                            model: {
                              value: _vm.name,
                              callback: function ($$v) {
                                _vm.name = $$v
                              },
                              expression: "name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("kamigame-markdown-editor", {
                        ref: "Editor",
                        attrs: { disabled: !_vm.editable, mode: "partial" },
                        on: { save: _vm.saveAsDraft },
                        model: {
                          value: _vm.text,
                          callback: function ($$v) {
                            _vm.text = $$v
                          },
                          expression: "text",
                        },
                      }),
                      _vm._v(" "),
                      _vm.editable
                        ? _c(
                            "div",
                            { attrs: { slot: "footer" }, slot: "footer" },
                            [
                              _c(
                                "div",
                                { staticClass: "card-top" },
                                [
                                  _c(
                                    "b-dropdown",
                                    {
                                      staticClass: "save_button",
                                      attrs: {
                                        disabled: _vm.disabled,
                                        variant: "primary",
                                        split: "",
                                        text: "下書き保存",
                                      },
                                      on: { click: _vm.saveAsDraft },
                                    },
                                    [
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          staticClass: "item-save",
                                          attrs: { disabled: _vm.disabled },
                                          on: {
                                            click: function ($event) {
                                              _vm.draftId
                                                ? _vm.showCheckDeleteDraftModal()
                                                : _vm.showCheckPublishModal()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  公開する\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: _vm.autoDraftSave
                                          ? "success"
                                          : "",
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.switchAutoDraftSave()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.autoDraftSave ? "✔ " : ""
                                          ) +
                                          "自動下書き保存\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("datasheets-loader-button", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      onLoaded: _vm.onDatasheetsLoaded,
                                      condition:
                                        _vm.pagePartialConditions[
                                          _vm.selectedPagePartialConditionIdx
                                        ],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.draftId
                        ? _c("div", { staticClass: "draft-info" }, [
                            _vm.isDraftContents
                              ? _c(
                                  "span",
                                  { attrs: { id: "draft-annotation" } },
                                  [
                                    _vm._v(
                                      "\n              この内容は " +
                                        _vm._s(_vm.draftAuthorName) +
                                        " によって\n              " +
                                        _vm._s(
                                          _vm.dateFormat(
                                            _vm.draftUpdatedAt,
                                            "yyyy/MM/dd HH:mm:ss"
                                          )
                                        ) +
                                        " に作成された下書きです\n              "
                                    ),
                                    !_vm.disabled && _vm.isDraftContents
                                      ? _c(
                                          "span",
                                          { staticClass: "float-bottom" },
                                          [
                                            _vm._v(
                                              "\n                (\n                "
                                            ),
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.showCheckDeleteDraftModal.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 公開する ")]
                                            ),
                                            _vm._v("/\n                "),
                                            _c(
                                              "a",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-modal",
                                                    rawName:
                                                      "v-b-modal.deleteDraftModal",
                                                    modifiers: {
                                                      deleteDraftModal: true,
                                                    },
                                                  },
                                                ],
                                                attrs: { href: "#" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [_vm._v("削除する")]
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                )\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _c("span", [
                                  _vm._v(
                                    "\n              変更された内容はまだ下書きに保存されていません。 下書きの最終更新は\n              " +
                                      _vm._s(
                                        _vm.dateFormat(
                                          _vm.draftUpdatedAt,
                                          "yyyy/MM/dd HH:mm:ss"
                                        )
                                      ) +
                                      " です。\n            "
                                  ),
                                ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    {
                      attrs: { "header-tag": "header", "footer-tag": "footer" },
                    },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _vm._v("フィルター条件設定 (プレビュー用)"),
                      ]),
                      _vm._v(" "),
                      _c("table", { staticClass: "table" }, [
                        _c(
                          "tbody",
                          [
                            _c("tr", [
                              _c(
                                "td",
                                { staticStyle: { width: "10%" } },
                                [
                                  _c("b-form-radio", {
                                    attrs: { value: -1 },
                                    model: {
                                      value:
                                        _vm.selectedPagePartialConditionIdx,
                                      callback: function ($$v) {
                                        _vm.selectedPagePartialConditionIdx =
                                          $$v
                                      },
                                      expression:
                                        "selectedPagePartialConditionIdx",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("td", { staticStyle: { width: "70%" } }, [
                                _vm._v("最大 10 件まで"),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticStyle: { width: "20%" } }),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.pagePartialConditions,
                              function (_, conditionIdx) {
                                return _c("tr", { key: conditionIdx }, [
                                  _c(
                                    "td",
                                    [
                                      _c("b-form-radio", {
                                        attrs: { value: conditionIdx },
                                        model: {
                                          value:
                                            _vm.selectedPagePartialConditionIdx,
                                          callback: function ($$v) {
                                            _vm.selectedPagePartialConditionIdx =
                                              $$v
                                          },
                                          expression:
                                            "selectedPagePartialConditionIdx",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "text" },
                                        model: {
                                          value:
                                            _vm.pagePartialConditions[
                                              conditionIdx
                                            ],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.pagePartialConditions,
                                              conditionIdx,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "pagePartialConditions[conditionIdx]",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.pagePartialConditions
                                                .length <= 1,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.pagePartialConditions.splice(
                                                conditionIdx,
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("削除する")]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              }
                            ),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("tfoot", [
                          _c(
                            "td",
                            { attrs: { colspan: "3" } },
                            [
                              _c(
                                "b-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.pagePartialConditions.push("")
                                    },
                                  },
                                },
                                [_vm._v("追加する")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { "font-size": "12px" } }, [
                        _c("p", [
                          _vm._v(
                            "\n              設定したフィルター条件を元にプレビューを行えます。記事部品利用時に指定する条件と同じものになります。(シート名:カラム名=値\n              ...)\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n              条件が無効である場合は、プレビューが表示されないので注意してください。また、この条件が設定されている場合、記事部品の保存時にすべての条件を元にカラム名の特定を行います。(何も設定していない場合はすべてのデータを元に特定を行う)\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n              もし、記事部品の保存時に提示されたカラム名が誤っている場合は、条件を追加することで正しく特定できるように試してください。\n            "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }